import service from './network';

export function queryMonitorList(data) {
    return service.request({
        url: '/area/monitor/query',
        method: 'post',
        data
    })
}

export function queryMonitorList1(data) {
    return service.request({
        url: '/monitor/query',
        method: 'post',
        data
    })
}

export function queryMonitorPreviewConf(data) {
    return service.request({
        url: '/hik/device/query/configure/image',
        method: 'post',
        data
    })
}

export function queryMonitorTree(data) {
    return service.request({
        url: '/area/monitor/tree',
        method: 'get',
    })
}

export function queryMonitorEquipmentTree1(data) {
    return service.request({
        url: '/monitor/tree',
        method: 'get',
        data
    })
}

// export function queryAreaMonitorConfig(data) {
//     return service.request({
//         url: '/area/monitor/config',
//         method: 'get',
//     })
// }

export function queryAreaMonitorPicture(data) {
    return service.request({
        url: '/user/verification/picture',
        method: 'get',
    })
}

export function queryAreaMonitorConfig(data) {
    return service.request({
        url: '/area/monitor/config',
        method: 'get',
        data
    })
}


export function deleteAllUnbindMonitor(data) {
    return service.request({
        url: '/equipment/delete/unbind/monitor',
        method: 'delete',
        data
    })
}

export function importMonitor(data) {
    return service.request({
        url: '/hik/fresh',
        method: 'put',
        data
    })
}


export function queryMonitorEquipmentTree(data) {
    return service.request({
        url: '/monitor/tree',
        method: 'get',
        data
    })
}

export function queryHikPreviewUrl(data) {
    return service.request({
        url: '/hik/preview/url',
        method: 'post',
        data
    })
}
export function queryHikPlaybackUrl(data) {
    return service.request({
        url: '/hik/playback',
        method: 'post',
        data
    })
}
